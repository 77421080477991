export const jobArea = {
	management: '#265ebf',
	embedded: '#bf26b6',
	app: '#fc984c',
	design: '#25c870',
	sales: '#ecba35',
	finance: '#fc4c4c',
	science: '#22b1b9',
}

export const colors = {
	jobArea: jobArea
}