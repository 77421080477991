/**
 * About us page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { thingosColors } from '../../../shared/src/Theme/theme';
import { Contact } from '../components/Contact';
import Container, { ColorContainer, Row } from '../components/Container';
import { Page } from '../components/Page';
import { SideBySide } from '../components/SideBySide';
import { TeamCard } from '../components/TeamCard/TeamCard';
import { Body, HeaderH1, HeaderH2, Quote } from '../components/Typography';
import { Wave } from '../components/Wave';
import { IndexLayout } from '../layouts';
import { colors } from '../styles/colors';

const IndexPage = () => {
	const { t } = useTranslation('about');

	return (
		<IndexLayout>
			<Page>
				<ColorContainer>
					<Container
						style={{
							textAlign: 'center',
							paddingTop: '3rem',
							paddingBottom: '3rem',
						}}
					>
						<Quote style={{ padding: '0 5rem', border: '0px' }}>{t('about.quote')}</Quote>
						<Body style={{ maxWidth: '100%' }}>{t('about.subline')}</Body>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH1 style={{ textAlign: 'center' }}>{t('about.title')}</HeaderH1>
						<Row style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
							<TeamCard
								name={t('about.thomas.name')}
								image={
									<StaticImage
										src="../content/people-thomas-no-bg.png"
										alt={t('about.thomas.name')}
									/>
								}
								linkedin=""
								position={t('about.thomas.position')}
								color={colors.jobArea.management}
							>
								{t('about.thomas.text')}
							</TeamCard>
							<TeamCard
								name={t('about.patrick.name')}
								image={
									<StaticImage
										src="../content/people-patrick-no-bg.png"
										alt={t('about.patrick.name')}
									/>
								}
								linkedin=""
								position={t('about.patrick.position')}
								color={colors.jobArea.embedded}
							>
								{t('about.patrick.text')}
							</TeamCard>
							<TeamCard
								name={t('about.jan.name')}
								image={
									<StaticImage src="../content/people-jan-no-bg.png" alt={t('about.jan.name')} />
								}
								linkedin=""
								position={t('about.jan.position')}
								color={colors.jobArea.embedded}
							>
								{t('about.jan.text')}
							</TeamCard>
							<TeamCard
								name={t('about.mario.name')}
								image={
									<StaticImage
										src="../content/people-mario-no-bg.png"
										alt={t('about.mario.name')}
									/>
								}
								linkedin=""
								position={t('about.mario.position')}
								color={colors.jobArea.embedded}
							>
								{t('about.mario.text')}
							</TeamCard>
							<TeamCard
								name={t('about.zaki.name')}
								image={
									<StaticImage src="../content/people-zaki-no-bg.png" alt={t('about.zaki.name')} />
								}
								linkedin=""
								position={t('about.zaki.position')}
								color={colors.jobArea.embedded}
							>
								{t('about.zaki.text')}
							</TeamCard>
							<TeamCard
								name={t('about.dominique.name')}
								image={
									<StaticImage
										src="../content/people-dominique-no-bg.png"
										alt={t('about.dominique.name')}
									/>
								}
								linkedin=""
								position={t('about.dominique.position')}
								color={colors.jobArea.app}
							>
								{t('about.dominique.text')}
							</TeamCard>
							<TeamCard
								name={t('about.benjamin.name')}
								image={
									<StaticImage
										src="../content/people-benjamin-no-bg.png"
										alt={t('about.benjamin.name')}
									/>
								}
								linkedin=""
								position={t('about.benjamin.position')}
								color={colors.jobArea.app}
							>
								{t('about.benjamin.text')}
							</TeamCard>
							<TeamCard
								name={t('about.uliana.name')}
								image={
									<StaticImage
										src="../content/people-uliana-no-bg.png"
										alt={t('about.uliana.name')}
									/>
								}
								linkedin=""
								position={t('about.uliana.position')}
								color={colors.jobArea.app}
							>
								TBD
							</TeamCard>
							<TeamCard
								name={t('about.julian.name')}
								image={
									<StaticImage
										src="../content/people-julian-no-bg.png"
										alt={t('about.julian.name')}
									/>
								}
								linkedin=""
								position={t('about.julian.position')}
								color={colors.jobArea.design}
							>
								{t('about.julian.text')}
							</TeamCard>
							<TeamCard
								name={t('about.marco.name')}
								image={
									<StaticImage
										src="../content/people-marco-no-bg.png"
										alt={t('about.marco.name')}
									/>
								}
								linkedin=""
								position={t('about.marco.position')}
								color={colors.jobArea.sales}
							>
								{t('about.marco.text')}
							</TeamCard>
							<TeamCard
								name={t('about.murielle.name')}
								image={
									<StaticImage
										src="../content/people-murielle-no-bg.png"
										alt={t('about.murielle.name')}
									/>
								}
								linkedin=""
								position={t('about.murielle.position')}
								color={colors.jobArea.finance}
							>
								{t('about.murielle.text')}
							</TeamCard>
						</Row>
					</Container>
					<Container>
						<Contact
							title={'Wanna be a Part of Our Team?'}
							text={
								'We are open to applications. Otherwise you could check out our open positions.'
							}
							buttonText={'Check open opportunities'}
							url={'../jobs'}
							backgroundColor={thingosColors.background.light}
						/>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="both" />
				<ColorContainer>
					<Container>
						<HeaderH2>{t('about.partners.title')}</HeaderH2>
						<StaticImage src="../content/partners.png" alt="Partners" />
					</Container>
				</ColorContainer>
				<ColorContainer backgroundColor={thingosColors.background.light}>
					<Container>
						<HeaderH2>{t('about.history.title')}</HeaderH2>
						<SideBySide>
							<Body>
								{t('about.history.text1')}
								<br />
								<br />
								{t('about.history.text2')}
							</Body>
							<StaticImage src="../content/company-timeline.png" alt="Timeline" />
						</SideBySide>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
export default IndexPage;
