/**
 * Team cared
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import { VStack } from '@domir/react-stacks';
import Color from 'color';
import * as React from 'react';
import styled, { CSSObject } from 'styled-components';

import { Tag } from '../../../../shared/src/Tag';
import { thingosColors } from '../../../../shared/src/Theme/theme';
import { HeaderH3 } from '../Typography';

import mask from './mask.svg';

// import maskPng from './mask.png'

const TeamH3 = styled(HeaderH3)({
	margin: '16px 0 4px 0',
	textAlign: 'center',
});

const TeamCardContainer = styled.div({
	width: '30%',
	paddingBottom: '2rem',

	'@media screen and (max-width: 600px)': {
		width: '100%',
	},
});

interface TeamCardProps {
	image: React.ReactNode;
	name: string;
	position: string;
	linkedin: string;
	color?: string;
}

export const TeamCard: React.FC<TeamCardProps> = props => {
	const { name, position, image, color } = props;

	return (
		<TeamCardContainer>
			<VStack style={{ alignItems: 'center', alignSelf: 'center' }}>
				<TeamImage hoverColor={color}>{image}</TeamImage>
				<TeamH3>{name}</TeamH3>
				<Tag small color={color} style={{ marginTop: '0.5rem' }}>
					{position}
				</Tag>
				{/* <TeamBody>{position}</TeamBody> */}
			</VStack>
		</TeamCardContainer>
	);
};

const TeamImageContainer = styled.div<{ hoverColor?: string }>(({ hoverColor }) => ({
	position: 'relative',
	// paddingTop: '40px',
	// backgroundColor: 'hsla(0, 100%, 30.5%, 0.484)',
	maskImage: `url(${mask})`,
	'-webkit-mask-image': `url(${mask})`,
	maskSize: 'contain',
	'-webkit-mask-size': 'contain',
	maskPosition: '50% 100%',
	'-webkit-mask-position': '50% 100%',
	maskRepeat: 'no-repeat',
	'-webkit-mask-repeat': 'no-repeat',
	// transform: `scale(1) translateY(-20px)`,

	'&:hover .thingos-image-content': {
		transition: '500ms ease-in-out',
		transform: `scale(1.1) translateY(-22.5px)`,
	},

	'&:hover > .thingos-image-background': {
		transition: '500ms ease-in-out',
		backgroundColor: hoverColor,
	},
}));

const TeamImageBackground = styled.div.attrs({ className: 'thingos-image-background' })(
	() =>
		({
			position: 'absolute',
			left: 0,
			top: '50%',
			transition: '1000ms ease-in-out',
			aspectRatio: '1',
			width: '100%',
			transform: 'translateY(-50%)',
			borderRadius: '50%',
			backgroundColor: `${thingosColors.background.light}`,
		} as CSSObject)
);

const TeamImageWrapper = styled.div.attrs({ className: 'thingos-image-wrapper' })(() => ({
	// backgroundColor: 'hsla(124, 100%, 41.4%, 0.484)',
	transition: '500ms ease-in-out',
	overflow: 'hidden',
	// paddingTop: '40px',
	// aspectRatio: '1',
}));

const TeamImageContent = styled.div.attrs({ className: 'thingos-image-content' })(() => ({
	// backgroundColor: 'hsla(200, 100%, 41.4%, 0.484)',
	transition: '500ms ease-in-out',
	// aspectRatio: '1',
	// transform: `scale(1) translateY(-80px)`,
}));

interface TeamImageProps {
	hoverColor?: string;
}

// react element button that contains image
export const TeamImage: React.FC<TeamImageProps> = props => {
	const { hoverColor, ...restProps } = props;
	const transparentColor = Color(hoverColor).alpha(0.3).string();

	return (
		<TeamImageContainer hoverColor={transparentColor}>
			<TeamImageBackground />
			<TeamImageWrapper>
				<TeamImageContent {...restProps} />
			</TeamImageWrapper>
		</TeamImageContainer>
	);
};
